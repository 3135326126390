<template lang="pug">
v-container(fill-height, fluid, grid-list-xl)
  v-layout(justify-center, wrap)
    v-flex(md12)
      v-divider
      h3 Versões
      DataTable(
        :fields="fields.versoesCliente"
        :dados="versoes"
        :loaded="loaded"
      )

      h3 Empresas
      DataTable(
        :fields="fields.empresas"
        :dados="empresas"
        :loaded="loaded"
      )

      h3 Módulos
      DataTable(
        :fields="fields.modulos"
        :dados="modulos"
        :loaded="loaded"
      )

      h3 Usuários
      DataTable(
        :fields="fields.usuarios"
        :dados="usuarios"
        :loaded="loaded"
      )

</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/api';

export default {
  data: () => ({
    loaded: false,
    title: 'Detalhes',
    versoes: [],
    empresas: [],
    usuarios: [],
    modulos: [],
    fields: api.fields,
  }),
  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    ...mapMutations('loader', ['showLoader', 'hideLoader']),

    async carregarDados() {
      api.refreshAuthProd(this.$store.state.auth);

      this.showLoader('Carregando dados...');
      try {
        const {
          nome, versoes, empresas, usuarios, modulos,
        } = await api.clientes.getDetalhes(this.$route.params.cliente);
        this.title = nome;
        this.versoes = versoes.sort((a, b) => new Date(b.versaocliente.data) - new Date(a.versaocliente.data));
        empresas.forEach((row) => {
          row.cnpj = row.cnpj == null || row.cnpj == '' ? row.cpf : row.cnpj;
        });
        this.empresas = empresas;
        this.usuarios = usuarios.sort((a, b) => b.ativo - a.ativo);
        this.modulos = modulos;
        
      } catch (e) {
        console.error(e);
        this.$toast.error('Ocorreu algum problema ao buscar as informações');
      } finally {
        this.hideLoader();
        this.loaded = true;
        
      }
    },
  },
  async mounted() {
    this.isControl(true);
    this.setTitle(`Detalhes: ${this.title}`);

    await this.carregarDados();
  },
  created() {
    this.$store.dispatch('auth/verify');
  },
};
</script>
